import React from 'react';
import { Button, Box, Typography, Container } from '@mui/material';

const Home = ({ onLogout }) => {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          Home
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          Welcome to the home page!
        </Typography>
        <Button
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
          onClick={onLogout}
        >
          Logout
        </Button>
      </Box>
    </Container>
  );
};

export default Home;